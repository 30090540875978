import React from "react"
import styled from "styled-components"
import { Link } from "gatsby"
import kebabCase from "lodash/kebabCase"
import { Subline } from "./Subline"

const Post = styled.article`
    display: flex;
    flex-direction: column;
    margin-top: 3.5rem;
    margin-bottom: 3.5rem;
`

const Title = styled.h2`
    color: #6e5773;
    position: relative;
    text-shadow: 0 12px 30px rgba(0, 0, 0, 0.15);
    margin-bottom: 0.75rem;
`

const Initiale = styled.span`
    position: absolute;
    font-size: 7rem;
    transform: translate(-50%, -50%);
    opacity: 0.08;
    user-select: none;
    z-index: -1;
`

const Excerpt = styled.p`
    grid-column: -1 / 1;
    margin-top: 1rem;
    margin-bottom: 1rem;
`

interface Props {
    title: string
    date: string
    excerpt: string
    slug: string
    timeToRead: number
    category: string
}

export class Article extends React.PureComponent<Props> {
    public render() {
        const { title, date, excerpt, slug, timeToRead, category } = this.props

        return (
            <Post>
                <Title>
                    <Link to={`/blog/${slug}`}>{title}</Link>
                </Title>
                <Subline>
                    {date} &mdash; {timeToRead} Min Read &mdash; In
                    <Link to={`/blog/categories/${kebabCase(category)}`}> {category}</Link>
                </Subline>
                <Excerpt>{excerpt}</Excerpt>
            </Post>
        )
    }
}
